import React from "react";

const Onlemler = () => (
  <div className="container">
    <h1>Önlemler</h1>
    <p>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem, officiis? At dolor sapiente, id,
      temporibus libero rerum obcaecati similique, aperiam architecto corporis voluptatibus eaque! Harum praesentium rem
      consequatur fugit officia.
    </p>
  </div>
);

export default Onlemler;
